import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import PropTypes from "prop-types";
import React from "react";
import SectionCareers from "../components/Sections/SectionCareers";
import Sections from "../components/Sections/Sections";
import { graphql } from "gatsby";

// ====

const Career = (props) => {
    const { data, pageContext, blok } = props;
    let doc;
    if (pageContext) {
        doc = JSON.parse(pageContext.story.content);
    } else {
        doc = blok;
    }
    const openGraph = {
        type: "website",
        title: doc.title,
        description: doc.body,
        image: doc.image,
    };

    const parsedSiteSettings = data?.settings
        ? JSON.parse(data?.settings.content)
        : {};

    return (
        <Layout location={props.location} siteSettings={data?.settings}>
            <Meta openGraph={openGraph} meta={doc.meta} />

            <section
                className="container-fluid p-0"
                style={{ marginTop: "10rem", marginBottom: "4rem" }}
            >
                {doc?.title && (
                    <div className="row justify-content-center align-items-center">
                        <div>
                            <h1
                                className="text-center mb-5 px-5
                brand-font-weight-60 brand-font-size-lg-100
                brand-font-weight-400
                brand-line-height-60 brand-line-height-lg-100
                title-slide-down-fade-in"
                            >
                                {doc.title}
                            </h1>

                            <div className="d-flex justify-content-center text-center text-uppercase">
                                {doc?.type && (
                                    <span className="me-5">
                                        <FontAwesomeIcon
                                            className="me-3"
                                            icon="clock"
                                        />
                                        {doc?.type}
                                    </span>
                                )}

                                {doc?.location && (
                                    <span>
                                        <FontAwesomeIcon
                                            className="me-3"
                                            icon="map-marker-alt"
                                        />
                                        {doc?.location}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </section>

            {doc?.sections && (
                <Sections
                    items={doc.sections}
                    settings={parsedSiteSettings}
                    showPurpleCurve={false}
                />
            )}

            <SectionCareers
                background="purple"
                careers={data?.careers?.nodes}
                isLastSection={true}
                showPurpleCurve={true}
                title="Other roles"
            />
        </Layout>
    );
};

export default Career;

Career.propTypes = {
    pageContext: PropTypes.object,
    blok: PropTypes.object,
};

Career.defaultProps = {
    pageContext: null,
    blok: null,
};

export const QUERY = graphql`
    query CareerQuery {
        careers: allStoryblokEntry(
            filter: { field_component: { eq: "career" } }
            sort: { fields: [published_at], order: [DESC] }
            limit: 5
        ) {
            nodes {
                full_slug
                published_at
                content
            }
        }

        settings: storyblokEntry(
            slug: { eq: "settings" }
            lang: { eq: "default" }
        ) {
            content
        }
    }
`;
