import sbSettings from "./sbSettings";
import sb404 from "./sb404";
import sbDefault from "../../templates/page";
import sbIndexPage from "../../pages/index";
import sbCareers from "../../templates/careers";
import sbCareer from "../../templates/career";
import sbGames from "../../templates/games";
import sbGame from "../../templates/game";
import sbNews from "../../templates/news";
import sbNewsPost from "../../templates/news_post";

const ComponentList = {
  settings: sbSettings,
  default: sbDefault,
  index: sbIndexPage,
  careers: sbCareers,
  career: sbCareer,
  games: sbGames,
  game: sbGame,
  news: sbNews,
  news_post: sbNewsPost,
};

const Components = (type) => {
  if (typeof ComponentList[type] === "undefined") {
    return sb404;
  }
  return ComponentList[type];
};

export default Components;
