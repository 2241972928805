import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { format, parseISO } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Background from "../components/Global/Background";
import Layout from "../components/Templates/Layout";
import Image from "../components/Global/Image";
import Link from "../components/Global/Link";
import Meta from "../utils/Meta";
import SocialLinks from "../components/Global/SocialLinks";
import SmartText from "../utils/TextHandler";

import PurpleCurveImage from "../images/background-curve-purple.svg";
import WhiteCurveImage from "../images/background-curve-white.svg";
import Carousel from "../components/Global/Carousel";

// ====

const News_Post = (props) => {
  const { data, pageContext, blok } = props;

  // const scrollRef = useRef();
  // const [scrollWidthPercent, setScrollWidthPercent] = useState(0);

  let doc;
  // let items;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
    // items = data.news;
  } else {
    doc = blok;
  }

  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
    image: doc.image,
  };

  // const onScrollNews = () => {
  //   const el = scrollRef?.current;
  //   const scrollLeftMax = el?.scrollWidth - el?.clientWidth;
  //   setScrollWidthPercent((el?.scrollLeft / scrollLeftMax) * 100);
  // };

  // const scrollLeftHandler = () => {
  //   const el = scrollRef?.current;
  //   const scrollLeftMax = el?.scrollWidth - el?.clientWidth;
  //   el.scroll({
  //     left: el.scrollLeft - scrollLeftMax * 0.2,
  //     behavior: "smooth",
  //   });
  // };

  // const scrollRightHandler = () => {
  //   const el = scrollRef?.current;
  //   const scrollLeftMax = el?.scrollWidth - el?.clientWidth;
  //   el.scroll({
  //     left: el.scrollLeft + scrollLeftMax * 0.2,
  //     behavior: "smooth",
  //   });
  // };

  const parsedSiteSettings = data?.settings
    ? JSON.parse(data?.settings?.content)
    : {};

  // const newsNodesMap = items?.nodes?.map((newsNode) => {
  //   try {
  //     const parsedNodeContent = JSON.parse(newsNode.content);

  //     return {
  //       full_slug: newsNode.full_slug,
  //       field_date_string: newsNode.field_date_string,
  //       ...parsedNodeContent,
  //     };
  //   } catch (err) {
  //     console.error(err);
  //     return {
  //       full_slug: newsNode.full_slug,
  //       field_date_string: newsNode.field_date_string,
  //     };
  //   }
  // });

  // const processedNewsNodesMap = newsNodesMap?.map((news, index) => {
  //   return (
  //     <div
  //       className="px-1 px-md-3 card brand-background-purple border-0"
  //       key={index}
  //     >
  //       <Image
  //         className="card-img-top brand-border-radius-20"
  //         src={news?.image?.filename}
  //         sm="400x300"
  //         md="400x300"
  //         lg="400x300"
  //         alt={news?.image?.alt}
  //       />

  //       <div className="card-body">
  //         {news.field_date_string && (
  //           <p
  //             className="my-3
  //             brand-copy-font brand-text-purple-light
  //             brand-font-size-13 brand-font-size-lg-15
  //             brand-font-weight-400
  //             brand-line-height-17 brand-line-height-lg-19-5"
  //           >
  //             {format(parseISO(news.field_date_string), "dd.MM.yyyy")}
  //           </p>
  //         )}

  //         {news.title && (
  //           <Link
  //             stretched={true}
  //             className="d-block
  //               my-3
  //               brand-copy-font
  //               brand-text-white"
  //             to={news.full_slug}
  //             button="false"
  //           >
  //             <p
  //               className="
  //                 brand-font-size-18 brand-font-size-lg-20
  //                 brand-font-weight-700
  //                 brand-line-height-24 brand-line-height-26"
  //             >
  //               {news.title}
  //             </p>
  //           </Link>
  //         )}
  //       </div>
  //     </div>
  //   );
  // });

  return (
    <Layout location={props.location} siteSettings={data?.settings}>
      <Meta openGraph={openGraph} meta={doc.meta} />

      <section className="brand-hero container-fluid p-0">
        <Background
          className={`d-flex align-items-center
                            justify-content-center ratio ratio-21x9`}
          src={doc?.image?.filename}
          sm="768x900"
          md="1920x900"
          lg="1920x900"
        />

        {doc?.has_curved_header && (
          <img
            src={WhiteCurveImage}
            className="brand-white-curve px-0"
            alt="White curve"
          />
        )}
      </section>

      <div className="container-fluid mb-5">
        {doc?.date && (
          <div className="row my-3 justify-content-center text-center">
            <p
              className="brand-copy-font brand-text-purple-light
            brand-font-size-13 brand-font-size-lg-15
            brand-line-height-17 brand-line-height-lg-19-5"
            >
              {format(parseISO(doc.date), "dd.MM.yyyy")}
            </p>
          </div>
        )}

        {doc?.title && (
          <h3
            className="text-center my-5 px-5
            brand-font-size-32 brand-font-size-lg-52
            brand-font-weight-400
            brand-line-height-32 brand-line-height-lg-52
            title-slide-down-fade-in
        "
          >
            {doc.title}
          </h3>
        )}

        <div className="row">
          {doc.body && (
            <div className="col-12 col-lg-6 offset-lg-3">
              <SmartText
                containerClassName="            
                  text-start text-lg-center
                  brand-copy-font
                  brand-news-post-body
                "
              >
                {doc.body}
              </SmartText>
            </div>
          )}

          <p
            className="mt-5 text-center
              brand-copy-font
              brand-font-size-15
              brand-font-weight-700
              brand-line-height-15"
          >
            SHARE
          </p>

          <SocialLinks
            className="brand-text-purple m-0 text-center"
            itemClassName="my-3 mx-3 brand-text-purple"
            links={[
              ["facebook", parsedSiteSettings?.facebook],
              ["twitter", parsedSiteSettings?.twitter],
              ["instagram", parsedSiteSettings?.instagram],
              ["linkedin", parsedSiteSettings?.linkedin],
            ]}
          />

          <div className="col-12 text-center mt-4 mb-5">
            <Link
              to="/news/"
              className={`
                px-4 py-3
                text-center
                brand-btn-rounded-purple-fill
              `}
            >
              <span
                className={`brand-font-size-15 brand-font-size-lg-18
                  brand-font-weight-700 brand-font-weight-lg-600
                  brand-line-height-15 brand-line-height-lg-24
                  brand-text-white
                `}
              >
                Back to News
              </span>
            </Link>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-5 px-0">
        <div className="row px-0">
          <img src={PurpleCurveImage} className="brand-purple-curve px-0" />
        </div>
      </div>

      <div
        className="
        brand-background-purple
        brand-text-white"
      >
        {/* <h2
          className="text-center p-0 py-3 pt-4 mb-0
            brand-font-size-40 brand-font-size-lg-100
            brand-line-height-40 brand-line-height-lg-100"
        >
          Read more
        </h2> */}
      </div>

      {/* <div className="pt-5 brand-background-purple">
        <Carousel
          className="d-block d-lg-none"
          itemsToShow={1}
          itemsToScroll={1}
          hasPurpleBackground={true}
        >
          {processedNewsNodesMap}
        </Carousel>

        <Carousel
          className="d-none d-lg-block"
          itemsToShow={4}
          itemsToScroll={1}
          hasPurpleBackground={true}
        >
          {processedNewsNodesMap}
        </Carousel>
      </div> */}
    </Layout>
  );
};

export default News_Post;

export const QUERY = graphql`
  query NewsPostUKPageQuery {
    settings: storyblokEntry(
      slug: { eq: "settings" }
      lang: { eq: "default" }
    ) {
      content
    }
  }
`;
// export const QUERY = graphql`
//   query NewsPostUKPageQuery {
//     # Grab news articles
//     news: allStoryblokEntry(
//       filter: { field_component: { eq: "news_post" } }
//       sort: { fields: [field_date_string], order: [DESC] }
//       limit: 4
//     ) {
//       nodes {
//         full_slug
//         field_date_string
//         content
//       }
//     }

//     settings: storyblokEntry(
//       slug: { eq: "settings" }
//       lang: { eq: "default" }
//     ) {
//       content
//     }
//   }
// `;

News_Post.propTypes = {
  pageContext: PropTypes.object,
  blok: PropTypes.object,
};

News_Post.defaultProps = {
  pageContext: null,
  blok: null,
};
