import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Pagination from "../components/Global/Pagination";
import SectionImagePanel from "../components/Sections/SectionImagePanel";
import Sections from "../components/Sections/Sections";
import SmartText from "../utils/TextHandler";

// ====

function trimSlash(path) {
  return path.replace(/\/$/, "");
}

const News = (props) => {
  const { data, pageContext, blok } = props;
  let { numPages, limit, skip, currentPage, homepage, parent_id } =
    pageContext || 0;

  // ^^ Omitted unused variables, kept as comment in case this was done as a reminder
  // for something being implemented later
  // let { numPages, currentPage, homepage } =
  // let { homepage } =
  //   pageContext || 0;
  let doc;
  let pageSlug;
  let items;
  if (data) {
    //    doc = JSON.parse(data.index.content);
    doc = JSON.parse(pageContext?.story?.content);
    pageSlug = trimSlash(pageContext.story.full_slug);
    // items = data?.news;
  } else {
    doc = blok;
    items = {
      nodes: [
        {
          full_slug: "news/gaming/featured-news",
          field_date_string: "2021-09-16 00:00",
          content:
            '{"title":"Article one", "preview":"Super important news here", "image":null}',
          parent_id: null,
        },
        {
          full_slug: "news/gaming/featured-news",
          field_date_string: "2021-09-16 00:00",
          content:
            '{"title":"Article one", "preview":"Super important news here", "image":null}',
          parent_id: null,
        },
        {
          full_slug: "news/gaming/featured-news",
          field_date_string: "2021-09-16 00:00",
          content:
            '{"title":"Article one", "preview":"Super important news here", "image":null}',
          parent_id: null,
        },
        {
          full_slug: "news/gaming/featured-news",
          field_date_string: "2021-09-16 00:00",
          content:
            '{"title":"Article one", "preview":"Super important news here", "image":null}',
          parent_id: null,
        },
        {
          full_slug: "news/gaming/featured-news",
          field_date_string: "2021-09-16 00:00",
          content:
            '{"title":"Article one", "preview":"Super important news here", "image":null}',
          parent_id: null,
        },
        {
          full_slug: "news/gaming/featured-news",
          field_date_string: "2021-09-16 00:00",
          content:
            '{"title":"Article one", "preview":"Super important news here", "image":null}',
          parent_id: null,
        },
        {
          full_slug: "news/gaming/featured-news",
          field_date_string: "2021-09-16 00:00",
          content:
            '{"title":"Article one", "preview":"Super important news here", "image":null}',
          parent_id: null,
        },
        {
          full_slug: "news/gaming/featured-news",
          field_date_string: "2021-09-16 00:00",
          content:
            '{"title":"Article one", "preview":"Super important news here", "image":null}',
          parent_id: null,
        },
      ],
    };
  }
  const openGraph = {
    type: "website",
    title: `${homepage ? doc.title : doc.pagination_title}`,
    description: `${homepage ? doc.body : doc.pagination_body}`,
    image: doc.image,
  };

  // Map the nodes to an object accepted by Sections.js
  // const newsContentList = items?.nodes?.map((newsNode, index) => {
  //   try {
  //     // Extract parsed content from newsNode
  //     const parsedContent = { ...JSON.parse(newsNode?.content) };

  //     // Return all info in one object so Sections.js can parse it
  //     return {
  //       ...newsNode,
  //       ...parsedContent,
  //       index,
  //     };
  //   } catch (err) {
  //     console.error(err);
  //     return {
  //       full_slug: newsNode.full_slug,
  //       field_date_string: newsNode.field_date_string,
  //     };
  //   }
  // });

  // const parsedSiteSettings = data?.settings
  //   ? JSON.parse(data?.settings.content)
  //   : {};

  // let featuredNewsPost = {};
  // if (data?.featured?.nodes?.length) {
  //   featuredNewsPost = {
  //     ...data?.featured?.nodes[0],
  //     ...JSON.parse(data?.featured?.nodes[0].content),
  //   };
  // }

  return (
    <Layout location={props.location} siteSettings={data?.settings}>
      <Meta openGraph={openGraph} meta={doc.meta} />

      {/* <section
        className="container-fluid p-0"
        style={{ marginTop: "8rem", marginBottom: "2rem" }}
      >
        {doc?.title && (
          <div className="row justify-content-center align-items-center">
            <div>
              <h1
                className="
                    col-12
                    text-center
                    mb-4
                    px-5
                    brand-font-weight-60 brand-font-size-lg-160
                    brand-font-weight-400
                    brand-line-height-60 brand-line-height-lg-160
                    title-slide-down-fade-in
                  "
              >
                {doc.title}
              </h1>

              {doc?.body && (
                <div
                  className="
                      col-12 col-lg-6 offset-lg-3
                      text-center
                      px-4 px-lg-0
                      brand-copy-font
                    "
                >
                  <SmartText>{doc.body}</SmartText>
                </div>
              )}
            </div>
          </div>
        )}
      </section>

      <section className="container-fluid px-3 px-lg-5">
        <div className="row d-flex d-lg-none">
          <Sections
            items={newsContentList.slice(0, 4)}
            settings={parsedSiteSettings}
          />
        </div>
        <div className="row d-none d-lg-flex">
          <Sections
            items={newsContentList.slice(0, 8)}
            settings={parsedSiteSettings}
          />
        </div>
      </section>

      <SectionImagePanel
        doc={{
          ...featuredNewsPost,
          image: featuredNewsPost.featured_image,
          body: featuredNewsPost.summary,
          headline: "FEATURED",
          button: "Read More",
          link: featuredNewsPost.full_slug,
          background: "coral",
          has_horizontal_margin: true,
        }}
        showPurpleCurve={false}
        oddEven={"even"}
      />

      {newsContentList.length > 4 && (
        <section className="container-fluid px-5">
          <div className="row d-flex d-lg-none">
            <Sections
              items={newsContentList.slice(4)}
              settings={parsedSiteSettings}
            />
          </div>
          <div className="row d-none d-lg-flex">
            <Sections
              items={newsContentList.slice(8)}
              settings={parsedSiteSettings}
            />
          </div>
        </section>
      )}

      {numPages && numPages > 1 && (
        <Pagination
          numPages={numPages}
          currentPage={currentPage}
          paginationPrefix={doc.pagination_prefix}
          homepage={pageSlug}
        />
      )}

      {doc?.sections && (
        <Sections items={doc.sections} settings={parsedSiteSettings} />
      )} */}
    </Layout>
  );
};

export default News;

export const QUERY = graphql`
  query NewsIndexDefaultQuery {
    # Query the data from the index page
    index: storyblokEntry(
      field_component: { eq: "news" }
      lang: { eq: "default" }
    ) {
      content
    }

    settings: storyblokEntry(
      slug: { eq: "settings" }
      lang: { eq: "default" }
    ) {
      content
    }
  }
`;
// export const QUERY = graphql`
//   query NewsIndexDefaultQuery($skip: Int!, $limit: Int!, $parent_id: [Int]) {
//     # Query the data from the index page
//     index: storyblokEntry(
//       field_component: { eq: "news" }
//       lang: { eq: "default" }
//     ) {
//       content
//     }

//     # Grab news articles
//     news: allStoryblokEntry(
//       filter: {
//         field_component: { eq: "news_post" }
//         parent_id: { in: $parent_id }
//         field_featured_boolean: { eq: false }
//       }
//       sort: { fields: [field_date_string], order: [DESC] }
//       limit: $limit
//       skip: $skip
//     ) {
//       nodes {
//         full_slug
//         field_date_string
//         content
//         parent_id
//       }
//     }

//     featured: allStoryblokEntry(
//       filter: {
//         field_component: { eq: "news_post" }
//         parent_id: { in: $parent_id }
//         field_featured_boolean: { eq: true }
//       }
//       sort: { fields: [field_date_string], order: [DESC] }
//       limit: $limit
//       skip: $skip
//     ) {
//       nodes {
//         full_slug
//         field_date_string
//         content
//         parent_id
//       }
//     }

//     settings: storyblokEntry(
//       slug: { eq: "settings" }
//       lang: { eq: "default" }
//     ) {
//       content
//     }
//   }
// `;

News.propTypes = {
  pageContext: PropTypes.object,
  blok: PropTypes.object,
  data: PropTypes.object,
};

News.defaultProps = {
  pageContext: null,
  blok: null,
  data: null,
};
