import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";


import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";

import Background from "../components/Global/Background";
import Sections from "../components/Sections/Sections";
import SmartText from "../utils/TextHandler";

// ====

const Games = (props) => {
  const { data, pageContext, blok } = props;
  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
    image: doc.image,
  };

  const parsedSiteSettings = data?.settings ? JSON.parse(data?.settings.content) : {};

  return (
    <Layout
      location={props.location}
      siteSettings={data?.settings}
    >
      <Meta openGraph={openGraph} meta={doc.meta} />

      <section
        className={`
          container-fluid
          ${doc?.has_curved_header ? '' : 'brand-hero-top-margin'}
          p-0
        `}
      >
        <Background
          className={`
            d-flex
            align-items-center
            justify-content-center
          `}
          src={doc?.image?.filename}
          sm="400x1024"
          md="1920x900"
          lg="1920x900"
        >
          {doc?.title && (
            <div className="row
              justify-content-center align-items-center
              brand-text-white"
            >
              <SmartText containerClassName="text-center px-5 brand-title title-slide-down-fade-in">
                {doc.title}
              </SmartText>

              {doc?.body &&
                <div className="text-center">
                  <SmartText>{doc.body}</SmartText>
                </div>
              }
            </div>
          )}
        </Background>
      </section>

      {doc?.sections &&
        <Sections
          items={doc.sections}
          settings={parsedSiteSettings}
        />
      }

    </Layout>
  );
};

export default Games;

Games.propTypes = {
  pageContext: PropTypes.object,
  blok: PropTypes.object,
};

Games.defaultProps = {
  pageContext: null,
  blok: null,
};

export const QUERY = graphql`
  query GamesQuery {

    settings: storyblokEntry(
      slug: { eq: "settings" }
      lang: { eq: "default" }
    ) {
      content
    }
  }
`;
