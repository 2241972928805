import { Helmet } from "react-helmet";
import React from "react";
import SbEditable from "storyblok-react";
import sbComponents from "../components/Storyblok/sbComponents";

const loadStoryblokBridge = function (cb) {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `//app.storyblok.com/f/storyblok-latest.js`;
    script.onload = cb;
    document.getElementsByTagName("head")[0].appendChild(script);
};

const getParam = function (val) {
    var result = "";
    var tmp = [];

    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === val) {
                result = decodeURIComponent(tmp[1]);
            }
        });

    return result;
};

class StoryblokEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = { story: null, bad: false };
    }

    componentDidMount() {
        // Storyblok Preview API access key.
        const key = process.env.GATSBY_SB_PREVIEW_KEY;

        // Must have a storyblok key.
        if (isNaN(getParam("_storyblok"))) {
            this.setState({ bad: true });
            return;
        }

        // Must have the API Access key.
        if (key === "") {
            this.setState({ bad: true });
            return;
        }

        loadStoryblokBridge(() => {
            // Init with access token from url.
            window.storyblok.init({
                accessToken: key,
            });

            this.initStoryblokEvents();
        });
    }

    loadStory() {
        window.storyblok.get(
            {
                slug: window.storyblok.getParam("path"),
                version: "draft",
                resolve_relations: [
                    "news_post.author",
                    "game_instance.link",
                    "thing_instance.link",
                ],
            },
            (data) => {
                this.setState({ story: data.story });
            }
        );
    }

    initStoryblokEvents() {
        this.loadStory();

        let sb = window.storyblok;

        sb.on(["change", "published"], (payload) => {
            this.loadStory();
        });

        sb.on("input", (payload) => {
            if (this.state.story && payload.story.id === this.state.story.id) {
                payload.story.content = sb.addComments(
                    payload.story.content,
                    payload.story.id
                );
                sb.resolveRelations(
                    payload.story,
                    [
                        "news_post.author",
                        "game_instance.link",
                        "thing_instance.link",
                    ],
                    () => {
                        this.setState({ story: payload.story });
                    }
                );
            }
        });

        sb.pingEditor(() => {
            if (sb.inEditor) {
                sb.enterEditmode();
            }
        });
    }

    render() {
        if (this.state.bad === true) {
            return (
                <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
                    <div>
                        <h1>Error</h1>
                        <p>There is nothing for you to see here</p>
                    </div>
                </div>
            );
        }

        if (this.state.story === null) {
            return (
                <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
                    <h5>Loading...</h5>
                </div>
            );
        }

        let content = this.state.story.content;

        return (
            <>
                <Helmet>
                    <style type="text/css">{`
          a {
            pointer-events: none;
          }
          div.brand-cookiebar {
            display: none !important; 
          }
          div.brand-cookiebar.modeEditor {
            display: block !important; 
          }
        `}</style>
                </Helmet>
                <SbEditable content={content}>
                    <>
                        {React.createElement(
                            sbComponents(
                                content.component,
                                window.storyblok.getParam(`path`)
                            ),
                            {
                                key: content._uid,
                                blok: content,
                            }
                        )}
                    </>
                </SbEditable>
            </>
        );
    }
}

export default StoryblokEntry;
