import Background from "../components/Global/Background";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import PropTypes from "prop-types";
import React from "react";
import SectionCareers from "../components/Sections/SectionCareers";
import Sections from "../components/Sections/Sections";
import SmartText from "../utils/TextHandler";
import WhiteCurveImage from "../images/background-curve-white.svg";
import { graphql } from "gatsby";

// ====

const Careers = (props) => {
    const { data, pageContext, blok } = props;
    console.log("data", data);
    let doc;
    if (pageContext) {
        doc = JSON.parse(pageContext.story.content);
    } else {
        doc = blok;
    }
    const openGraph = {
        type: "website",
        title: doc.title,
        description: doc.body,
        image: doc.image,
    };

    const parsedSiteSettings = data?.settings
        ? JSON.parse(data?.settings.content)
        : {};

    return (
        <Layout location={props.location} siteSettings={data?.settings}>
            <Meta openGraph={openGraph} meta={doc.meta} />

            <section
                className={`brand-hero ${
                    doc?.has_curved_header ? "" : "brand-hero-top-margin"
                } container-fluid p-0`}
            >
                <Background
                    className={`
            d-flex flex-column
            align-items-center justify-content-center
            brand-text-white
          `}
                    src={doc?.image?.filename}
                    sm="768x1024"
                    md="768x1024"
                    lg="1920x900"
                >
                    {doc?.title && (
                        <div className="row">
                            <SmartText
                                containerClassName="
                  col-12
                  text-center
                  brand-title
                  title-slide-down-fade-in
                "
                            >
                                {doc.title}
                            </SmartText>

                            {doc?.body && (
                                <div
                                    className="
                    col-12 col-lg-10 offset-lg-1
                    text-center
                    px-4 px-lg-0
                    brand-copy-font
                  "
                                >
                                    <SmartText>{doc.body}</SmartText>
                                </div>
                            )}
                        </div>
                    )}
                </Background>

                {doc?.has_curved_header && (
                    <img
                        src={WhiteCurveImage}
                        className="brand-white-curve px-0"
                        alt="White curve"
                    />
                )}
            </section>

            <SectionCareers
                background="transparent"
                careers={data?.careers?.nodes}
                className="brand-pull-up-section-container"
            />

            {doc?.sections && (
                <Sections items={doc.sections} settings={parsedSiteSettings} />
            )}
        </Layout>
    );
};

export default Careers;

Careers.propTypes = {
    pageContext: PropTypes.object,
    blok: PropTypes.object,
};

Careers.defaultProps = {
    pageContext: null,
    blok: null,
};

export const QUERY = graphql`
    query CareersQuery {
        careers: allStoryblokEntry(
            filter: { field_component: { eq: "career" } }
            sort: { fields: [published_at], order: [DESC] }
            limit: 5
        ) {
            nodes {
                full_slug
                published_at
                content
            }
        }

        settings: storyblokEntry(
            slug: { eq: "settings" }
            lang: { eq: "default" }
        ) {
            content
        }
    }
`;
